var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { JourneyEventType } from '../types';
import { dispatch } from './dispatch';
/**
 * Forwards message event to respective event handler by dispatching the internal event
 * @param event MessageEvent
 */
export var onMessageEvent = function (event) {
    var _a = event.data, type = _a.type, journeyId = _a.journeyId, _b = _a.payload, payload = _b === void 0 ? {} : _b;
    switch (type) {
        case JourneyEventType.init:
            dispatch('init');
            return;
        case JourneyEventType.enterFullScreen:
            dispatch('enterFullScreen', __assign({ journeyId: journeyId }, payload));
            return;
        case JourneyEventType.exitFullScreen:
            dispatch('exitFullScreen', __assign({ journeyId: journeyId }, payload));
            return;
        case JourneyEventType.closeJourney:
            dispatch('closeJourney', __assign({ journeyId: journeyId }, payload));
            return;
        case JourneyEventType.formChange:
            dispatch('formChange', __assign({ journeyId: journeyId }, payload));
            return;
        case JourneyEventType.pageView:
            dispatch('pageView', __assign({ journeyId: journeyId }, payload));
            return;
        default:
            dispatch(type, __assign({ journeyId: journeyId }, payload));
            return;
    }
};
