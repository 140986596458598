var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { addJourneyById } from '..';
import { JOURNEY_URL, NODE_ENV } from '../../config';
import { JourneyEventType } from '../../types';
import { addTimeout } from '../../utils/addTimeout';
import { getQueryParams } from '../../utils/getQueryParams';
import { mutateIframeStyling } from '../../utils/mutateIframeStyling';
function iframeCreate(params) {
    var _a;
    var _b;
    var journeyId = params.journeyId, lang = params.lang, otherParams = __rest(params
    // add queryParams
    , ["journeyId", "lang"]);
    // add queryParams
    otherParams['queryParams'] = getQueryParams();
    // get container where iframe will be placed in
    var container = document.getElementById("epilot-journey-" + journeyId);
    var iframe = document.createElement('iframe');
    // journeyUrl can be overridden (alternative hosting for journey app)
    var journeyUrl = (_b = params.journeyUrl) !== null && _b !== void 0 ? _b : JOURNEY_URL;
    iframe.src = journeyUrl + "?journeyId=" + journeyId + (otherParams.mode ? "&mode=" + otherParams.mode : '') + (typeof otherParams.topBar === 'boolean'
        ? "&topBar=" + otherParams.topBar
        : '') + (lang ? "&lang=" + lang : '');
    iframe.style.border = 'none';
    if (NODE_ENV === 'test') {
        iframe.setAttribute('data-testid', journeyId);
    }
    // pass params to iframe once loaded
    if (iframe) {
        iframe.onload = function () {
            var timeout = addTimeout() || 500;
            // send completion event back to iframe
            // TODO: Instead of the timeout, make the iframe send a ready event so we know we can send the postMessages
            setTimeout(function () {
                var _a;
                (_a = iframe === null || iframe === void 0 ? void 0 : iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
                    type: JourneyEventType.init,
                    status: 'effect',
                    journeyId: journeyId,
                    payload: otherParams
                }, '*');
                // Set styling depending on mode
                if (params.mode === 'inline') {
                    mutateIframeStyling(iframe, params);
                }
            }, timeout);
        };
        if (params.mode !== 'inline') {
            mutateIframeStyling(iframe, params);
        }
        else if (params.mode === 'inline') {
            iframe.style.cssText =
                'display:block; border: none; min-width: 100%; width: 1px';
        }
    }
    // append iframe to HTML
    if (container) {
        container.appendChild(iframe);
        iframe.id = "epilot-journey-embedded-" + journeyId;
    }
    else {
        iframe.id = "epilot-journey-" + journeyId;
        document.body.appendChild(iframe);
    }
    // add journey to map and return created
    var journey = (_a = {},
        _a[journeyId] = { el: iframe, isInitialized: true },
        _a);
    addJourneyById(journeyId, {
        el: iframe,
        isInitialized: true
    });
    return journey;
}
export default iframeCreate;
